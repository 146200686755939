<template>
  <div>
    <my-nav-bar
      title="特惠购"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-tabs v-model="active" class="promote-sales-tabs" sticky swipeable color="#1A6FF2" title-active-color="#1A6FF2" @change="handleRefresh">
        <van-tab title="装车出库" :badge="outTotal">
          <div class="tab-body">
            <van-empty v-show="showEmpty" description="暂无数据" />
            <div
              v-for="(plan, index) in list"
              :key="index"
              class="content"
              @click="toDetailView(plan)"
            >
              <van-cell title="配送员" :value="plan.employee.name" />
              <van-cell title="开始时间" :value="plan.promote_sales.start_at" />
              <van-cell title="结束时间" :value="plan.promote_sales.end_at" />
            </div>
          </div>
        </van-tab>
        <van-tab :badge="inTotal" title="回单核对">
          <van-list ref="list" v-model="loading" :finished="finished" class="tab-body" @load="getList2">
            <div class="content">
              <van-cell title="查看历史记录" center>
                <van-switch v-model="showHistory" size="20px" @change="handleSwitchChange" />
              </van-cell>
            </div>
            <div
              v-for="(plan, index) in list2"
              :key="index"
              class="content"
              @click="toDetailView(plan)"
            >
              <van-cell title="配送员" :value="plan.employee.name" />
              <van-cell title="开始时间" :value="plan.promote_sales.start_at" />
              <van-cell title="结束时间" :value="plan.promote_sales.end_at" />
              <van-cell v-if="plan.in_remark" title="备注：" :label="plan.in_remark" />
            </div>
          </van-list>
          <van-empty v-show="showEmpty2" description="暂无数据" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getPromoteSalesPlans } from '@/api/promote-sales'
export default {
  name: 'PromoteSales',
  components: { myNavBar },
  data() {
    return {
      active: 0,
      list: [],
      list2: [],
      outTotal: '',
      inTotal: '',
      showEmpty: false,
      showEmpty2: false,
      showHistory: false,
      loading: false,
      finished: false,
      listQuery2: {
        page: 0
      }
    }
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)

    this.$eventBus.$on('refresh', () => {
      setTimeout(() => {
        this.handleRefresh()
      }, 500)
    })

    this.getList()
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path !== '/promote-sales-detail') {
      this.$store.commit('rmKeepAlive', this.$options.name)
      this.$eventBus.$off('refresh')
    }
    next()
  },
  methods: {
    handleRefresh() {
      if (this.active === 0) {
        this.getList()
      } else {
        this.reloadList2()
      }
    },
    getList() {
      const params = {
        type: this.active + 1
      }
      this.beginLoad()
      getPromoteSalesPlans(params).then(res => {
        this.endLoad()
        this.outTotal = res.data.out_total > 0 ? res.data.out_total : ''
        this.inTotal = res.data.in_total > 0 ? res.data.in_total : ''
        this.list = res.data.list
        this.showEmpty = this.list.length === 0
      })
    },
    getList2() {
      this.listQuery2.page++
      this.listQuery2.type = this.active + 1
      this.listQuery2.show_history = Number(this.showHistory)
      getPromoteSalesPlans(this.listQuery2).then(res => {
        this.outTotal = res.data.out_total > 0 ? res.data.out_total : ''
        this.inTotal = res.data.in_total > 0 ? res.data.in_total : ''
        this.list2 = this.list2.concat(res.data.list)
        this.showEmpty2 = this.list2.length === 0
        this.loading = false
        this.finished = res.data.list.length < 10
      })
    },
    toDetailView(plan) {
      this.$router.push({
        path: '/promote-sales-detail',
        query: {
          plan_id: plan.id,
          type: this.active + 1,
          employee_name: plan.employee.name,
          is_history: this.active === 0 ? 0 : Number(this.showHistory)
        }
      })
    },
    handleSwitchChange() {
      this.reloadList2()
    },
    reloadList2() {
      this.listQuery2.page = 0
      this.finished = false
      this.showEmpty2 = false
      this.list2 = []
      if (this.$refs.list) {
        this.$refs.list.check()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-body {
  margin-top: 10px;
}
.content {
  margin-bottom: 10px;
  padding: 0 10px;
}
</style>
<style>
.promote-sales-tabs .van-tabs__content {
  min-height: calc(100vh - 100px);
}
</style>
