var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "特惠购",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('van-tabs', {
    staticClass: "promote-sales-tabs",
    attrs: {
      "sticky": "",
      "swipeable": "",
      "color": "#1A6FF2",
      "title-active-color": "#1A6FF2"
    },
    on: {
      "change": _vm.handleRefresh
    },
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, [_c('van-tab', {
    attrs: {
      "title": "装车出库",
      "badge": _vm.outTotal
    }
  }, [_c('div', {
    staticClass: "tab-body"
  }, [_c('van-empty', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showEmpty,
      expression: "showEmpty"
    }],
    attrs: {
      "description": "暂无数据"
    }
  }), _vm._l(_vm.list, function (plan, index) {
    return _c('div', {
      key: index,
      staticClass: "content",
      on: {
        "click": function click($event) {
          return _vm.toDetailView(plan);
        }
      }
    }, [_c('van-cell', {
      attrs: {
        "title": "配送员",
        "value": plan.employee.name
      }
    }), _c('van-cell', {
      attrs: {
        "title": "开始时间",
        "value": plan.promote_sales.start_at
      }
    }), _c('van-cell', {
      attrs: {
        "title": "结束时间",
        "value": plan.promote_sales.end_at
      }
    })], 1);
  })], 2)]), _c('van-tab', {
    attrs: {
      "badge": _vm.inTotal,
      "title": "回单核对"
    }
  }, [_c('van-list', {
    ref: "list",
    staticClass: "tab-body",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList2
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_c('div', {
    staticClass: "content"
  }, [_c('van-cell', {
    attrs: {
      "title": "查看历史记录",
      "center": ""
    }
  }, [_c('van-switch', {
    attrs: {
      "size": "20px"
    },
    on: {
      "change": _vm.handleSwitchChange
    },
    model: {
      value: _vm.showHistory,
      callback: function callback($$v) {
        _vm.showHistory = $$v;
      },
      expression: "showHistory"
    }
  })], 1)], 1), _vm._l(_vm.list2, function (plan, index) {
    return _c('div', {
      key: index,
      staticClass: "content",
      on: {
        "click": function click($event) {
          return _vm.toDetailView(plan);
        }
      }
    }, [_c('van-cell', {
      attrs: {
        "title": "配送员",
        "value": plan.employee.name
      }
    }), _c('van-cell', {
      attrs: {
        "title": "开始时间",
        "value": plan.promote_sales.start_at
      }
    }), _c('van-cell', {
      attrs: {
        "title": "结束时间",
        "value": plan.promote_sales.end_at
      }
    }), plan.in_remark ? _c('van-cell', {
      attrs: {
        "title": "备注：",
        "label": plan.in_remark
      }
    }) : _vm._e()], 1);
  })], 2), _c('van-empty', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showEmpty2,
      expression: "showEmpty2"
    }],
    attrs: {
      "description": "暂无数据"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }